import React from "react";
import { isBrowser } from "@emotion/core/src/utils";
import Legal from "../templates/Legal";

const unpublishedLegal = () => {
    if(!isBrowser) {
        return null;
    }
    const previewData = window.__PRISMIC_PREVIEW_DATA__

    return <Legal data={previewData} />
}

export default unpublishedLegal;